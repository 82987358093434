import { Button, Input, Textarea } from "@chakra-ui/react";
import moment from "moment";
import React, { FC, useCallback, useRef, useState } from "react";
import ReactToPrint from "react-to-print";
import "./App.css";
import { InputLabel } from "./InputLabel";

const App: FC = () => {
  const printComponentRef = useRef<HTMLDivElement>(null);

  const reactToPrintContent = useCallback(() => {
    return printComponentRef.current;
  }, []);

  const [courseName, setCourseName] = useState("");
  const [studentName, setStudentName] = useState("");
  const [location, setLocation] = useState(
    "J & B DESIGNING TRAINING AND SERVICES LLP, JAMNAGAR"
  );
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [issueDate, setIssueDate] = useState(moment().format("YYYY-MM-DD"));

  return (
    <div className="h-screen flex flex-col md:flex-row">
      <div className="flex-1 text-center">
        <h1 className="text-4xl">Data</h1>
        <div className="mt-3">
          <div className=" p-4 border rounded-xl md:w-2/3 mx-3 md:mx-auto text-left flex flex-col gap-y-2">
            <div>
              <InputLabel label="Course Name" />
              <Input
                placeholder="Course Name"
                value={courseName}
                onChange={(e) => setCourseName(e.target.value)}
              />
            </div>
            <div>
              <InputLabel label="Student Name" />
              <Input
                placeholder="Student Name"
                value={studentName}
                onChange={(e) => setStudentName(e.target.value)}
              />
            </div>
            <div>
              <InputLabel label="Location" />
              <Textarea
                placeholder="Location"
                value={location}
                onChange={(e) => setLocation(e.target.value)}
              />
            </div>
            <div className="flex flex-col md:flex-row items-center gap-x-2">
              <div className="flex-1">
                <InputLabel label="Start date" />
                <Input
                  type={"date"}
                  value={moment(startDate).format("YYYY-MM-DD")}
                  onChange={(e) => setStartDate(e.target.value)}
                />
              </div>
              <div className="flex-1">
                <InputLabel label="End date" />
                <Input
                  type={"date"}
                  value={moment(endDate).format("YYYY-MM-DD")}
                  onChange={(e) => setEndDate(e.target.value)}
                />
              </div>
            </div>
            <div>
              <InputLabel label="Issue date" />
              <Input
                type={"date"}
                value={moment(issueDate).format("YYYY-MM-DD")}
                onChange={(e) => setIssueDate(e.target.value)}
              />
            </div>
            <ReactToPrint
              content={reactToPrintContent}
              copyStyles={true}
              trigger={() => (
                <Button my={2} colorScheme={"blue"}>
                  Print
                </Button>
              )}
            />
          </div>
        </div>
      </div>
      <div className="flex-1 h-full hidden md:block print:block">
        <h1 className="text-center text-4xl">Preview</h1>
        <div ref={printComponentRef}>
          <Certificate
            courseName={courseName}
            studentName={studentName}
            location={location}
            startDate={startDate ? moment(startDate).format("DD-MM-YYYY") : "-"}
            endDate={endDate ? moment(endDate).format("DD-MM-YYYY") : "-"}
            issueDate={issueDate ? moment(issueDate).format("DD-MM-YYYY") : "-"}
          />
        </div>
      </div>
    </div>
  );
};

interface CertificateDetails {
  studentName: string;
  courseName: string;
  location: string;
  startDate: string;
  endDate: string;
  issueDate: string;
}

const Certificate: FC<CertificateDetails> = ({
  courseName,
  studentName,
  location,
  startDate,
  endDate,
  issueDate,
}) => {
  return (
    <>
      <div
        className="text-center border-red-600 p-4"
        style={{
          borderWidth: "30px",
        }}
      >
        <div className="text-center border-4 border-b-0 border-red-600 p-4">
          <div className="text-3xl font-goldman mt-9">
            CERTIFICATE OF COMPLETION
          </div>
          <div className="text-7xl font-extrabold font-dancing-script">
            Essential
          </div>
          <div className="my-16">
            <FormField title="IN" value={courseName} />
            <FormField title="AWARDED TO" value={studentName} />
            <FormField title="AT" value={location} />
            <FormField title="DURING" value={`${startDate} TO ${endDate}`} />
          </div>
          <section className="mb-16 mt-24">
            <div className="flex gap-x-10">
              <div className="flex-1">
                <div className="border-b-2 border-b-gray-900 border-dotted text-xl">
                  &nbsp;
                </div>
                <div className="mt-3">CENTRE HEAD</div>
              </div>
              <div className="flex-1">
                <div className="border-b-2 border-b-gray-900 border-dotted text-xl">
                  {issueDate}
                </div>
                <div className="mt-3">DATE OF ISSUE</div>
              </div>
            </div>
          </section>
          <section className="mt-16">
            <img
              className="w-5/12 mx-auto"
              src="/jb-designing.png"
              alt="jb-designing"
            />
          </section>
          <section className="mt-10">
            <div>
              <span className="font-bold">Address:</span> 303, Antila - The
              Business Hub, Near. Haria School, Janta Fatak, Jamnagar-361006
            </div>
            <div>
              <span className="font-bold">Contact:</span> 8238785980 /
              9671188888
            </div>
          </section>
        </div>
        <div className="bg-red-600 font-bold text-xl text-white text-center py-1">
          J &amp; B Designing Training and Services LLP
        </div>
      </div>
    </>
  );
};

const FormField: FC<{ title: string; value?: string }> = ({ title, value }) => {
  return (
    <div className="flex my-4">
      <div style={{ flex: 4 }}>
        <div className="text-xl text-left font-goldman">{title}</div>
      </div>
      <div
        style={{ flex: 8 }}
        className="border-b-2 border-b-gray-900 text-left"
      >
        <div className="text-xl">{value}</div>
      </div>
    </div>
  );
};

export default App;
